import { useMemo } from "react"
import styled from "styled-components"

import { addDays, getDay } from "date-fns"
import { default as Highcharts } from "highcharts"

import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { GraphCard } from "src/components/Homes/DeviceDetails/Overview/GraphCard"
import { useFetchMoldInsightsByDate } from "src/data/moldInsights/moldInsightsQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { TClockType } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import { BarChart } from "src/ui/Graphs/BarChart"
import { Box } from "src/ui/Graphs/GenericTooltip"
import { TBarChartMultiSeriesData } from "src/ui/Graphs/graphTypes"
import { MInfo } from "src/ui/Info/MInfo"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatDate, getStoredSelectedTimezone } from "src/utils/l10n"

const GRAPH_HEIGHT = 300

export function MoldInsightsGraphCard({
  dateRange,
  homegroupId,
}: {
  dateRange: IGraphDateRange
  homegroupId?: string
}) {
  const user = useGetUser()
  const { orgId } = useOrganization()
  const { t, langKeys } = useTranslate()

  const { timezone } = getStoredSelectedTimezone()

  const { startDate, endDate } = dateRange

  const fetchMoldRiskLevelGroup = useFetchMoldInsightsByDate({
    orgId,
    filters: { startDate, endDate, homegroupId },
  })

  const hasMoldRiskData = (fetchMoldRiskLevelGroup.data?.data.length ?? 0) > 0

  const { lowMoldRisk, mediumMoldRisk, highMoldRisk } = useMemo(() => {
    const low: [number, number][] = []
    const medium: [number, number][] = []
    const high: [number, number][] = []

    fetchMoldRiskLevelGroup.data?.data.forEach((item) => {
      const epochTime = new Date(item.grouped_by).getTime()

      low.push([epochTime, item.aggregation.count_low_level])
      medium.push([epochTime, item.aggregation.count_medium_level])
      high.push([epochTime, item.aggregation.count_high_level])
    })

    return {
      lowMoldRisk: low,
      mediumMoldRisk: medium,
      highMoldRisk: high,
    }
  }, [fetchMoldRiskLevelGroup.data?.data])

  const moldRiskData: TBarChartMultiSeriesData[] = [
    {
      name: t(langKeys.risk_of_mold_high),
      data: highMoldRisk,
      borderColor: mColors.systemErrorDark,
    },

    {
      name: t(langKeys.risk_of_mold_medium),
      data: mediumMoldRisk,
      borderColor: mColors.systemWarningDark,
    },
    {
      name: t(langKeys.risk_of_mold_low),
      data: lowMoldRisk,
      borderColor: mColors.systemGoodDark,
    },
  ]

  function getGraphAxisOptions():
    | {
        xAxisOptions: Highcharts.XAxisOptions
        yAxisOptions: Highcharts.YAxisOptions
      }
    | undefined {
    const title: Highcharts.YAxisOptions["title"] = {
      text: t(langKeys.number_of_units),
      style: {
        color: mColors.textTertiary,
      },
    }

    if (!hasMoldRiskData) {
      return {
        xAxisOptions: {
          min: startDate.getTime(),
          max: endDate.getTime(),
          dateTimeLabelFormats: { month: "%b '%y" },
        },
        yAxisOptions: {
          tickInterval: 5,
          tickPositions: [0, 5, 10, 15, 20, 25],
          title,
        },
      }
    }

    const tickPositions = fetchMoldRiskLevelGroup.data?.data.map((item) =>
      new Date(item.grouped_by).getTime()
    )

    return {
      xAxisOptions: {
        startOfWeek: getDay(startDate),
        tickPositions,
        labels: {
          formatter: function () {
            return Highcharts.dateFormat("%e %b", this.pos)
          },
        },
      },
      yAxisOptions: {
        title,
      },
    }
  }

  return (
    <GraphCard
      title={
        <GraphTitle>
          {t(langKeys.mold_analytics_graph_title)}
          <MInfo content={t(langKeys.mold_analytics_graph_tootltip_info)} />
        </GraphTitle>
      }
      titleVariant="heading3"
      isLoading={fetchMoldRiskLevelGroup.isLoading}
      hideGraphBorder={false}
      hidden={false}
      info={t(langKeys.mold_analytics_graph_info)}
    >
      <BarChart
        dataSeriesType="multi"
        data={moldRiskData}
        timezone={timezone.tzCode}
        stackSeries={true}
        clockType={user.clock_type}
        showLegend={false}
        height={GRAPH_HEIGHT}
        borderRadius={2}
        seriesHoverBrightness={0.03}
        interactive={true}
        multiSeriesColors={[
          mColors.systemErrorLight,
          mColors.systemWarningLight,
          mColors.systemGoodLight,
        ]}
        {...getGraphAxisOptions()}
        tooltip={{
          decimals: 0,
          formatter: ({ date, points }) => {
            return (
              <Tooltip
                date={date}
                clockType={user.clock_type}
                points={{
                  high: points?.[0]?.y ?? 0,
                  elevated: points?.[1]?.y ?? 0,
                  low: points?.[2]?.y ?? 0,
                  total: points?.[0]?.total ?? 0,
                }}
              />
            )
          },
          unit: "",
        }}
      />
    </GraphCard>
  )
}

function Tooltip({
  date,
  clockType,
  points,
}: {
  date: Date
  clockType: TClockType
  points: { low: number; elevated: number; high: number; total: number }
}) {
  const { t, langKeys } = useTranslate()

  const formattedStartDate = formatDate({
    date: date.toISOString(),
    clockType: clockType,
    timezone: "",
    excludeTime: true,
  })

  const formattedEndDate = formatDate({
    date: addDays(date, 7).toISOString(),
    clockType: clockType,
    timezone: "",
    excludeTime: true,
  })

  const tooltipData = [
    {
      label: t(langKeys.mold_analytics_risk_of_mold_high_tooltip),
      value: points.high,
    },
    {
      label: t(langKeys.mold_analytics_risk_of_mold_medium_tooltip),
      value: points.elevated,
    },
    {
      label: t(langKeys.mold_analytics_risk_of_mold_low_tooltip),
      value: points.low,
    },
  ]

  return (
    <Box>
      <Header>
        <MText variant="bodyS">{`${formattedStartDate} - ${formattedEndDate}`}</MText>
      </Header>
      <Content>
        {tooltipData.map(({ label, value }) => (
          <TooltipContent
            key={label}
            units={value}
            total={points.total}
            label={label}
          />
        ))}
      </Content>
    </Box>
  )
}

function TooltipContent({
  units,
  total,
  label,
}: {
  units: number
  total: number
  label: string
}) {
  const { t, langKeys } = useTranslate()
  const result = units / total
  const percentage = ((isNaN(result) ? 0 : result) * 100).toFixed(0)

  return (
    <div>
      <MText variant="subtitleS">
        <span>{label}: </span>
        <span>{`${units} ${t(langKeys.unit, { count: units })}`}</span>
      </MText>
      <MText color="tertiary">{`(${percentage}%)`}</MText>
    </div>
  )
}

const Header = styled(MText)`
  border-radius: 0.25rem 0.25rem 0 0;
  padding: ${spacing.S} ${spacing.M};
  background: ${mColors.primaryLight};
`

const Content = styled(MText)`
  display: grid;
  gap: ${spacing.XS};
  padding: ${spacing.S} ${spacing.M};
`

const GraphTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`
