import { useState } from "react"
import styled from "styled-components"

import { subDays, subWeeks } from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"

import { NoMoldInsights } from "src/components/EmptyState/NoMoldInsights"
import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { InsightFilters } from "src/components/Reports/InsightFilters"
import { MoldInsightsGraphCard } from "src/components/Reports/MoldInsights/MoldInsightsGraphCard"
import { MoldInsightsTable } from "src/components/Reports/MoldInsights/MoldInsightsTable"
import { TimezoneSelect } from "src/components/Reports/TimezoneSelect"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { TTimezone } from "src/data/timezones/timezones"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { IBreadcrumb } from "src/ui/Breadcrumbs/Breadcrumbs"
import { TSelectOption } from "src/ui/DropdownSelect/DropdownSelect"
import { BreadcrumbView } from "src/ui/Layout/BreadcrumbView"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { getStoredSelectedTimezone } from "src/utils/l10n"

export function MoldInsights() {
  const { t, langKeys } = useTranslate()

  const { orgId } = useOrganization()

  const { available: canAccessAdvancedMoldInsights, required_plan_types } =
    useFeatureAvailability({
      feature: "advanced_mould_risk_analytics",
    })

  const [selectedHomegroup, setSelectedHomegroup] =
    useState<TSelectOption<string> | null>(null)

  const [timezone, setTimezone] = useState<TTimezone>(
    getStoredSelectedTimezone().timezone
  )

  const [dateRange, setDateRange] = useState<IGraphDateRange>({
    startDate: subWeeks(new Date(), 5),
    endDate: new Date(),
  })

  const fetchDevices = useFetchDevices({
    orgId,
    filter: { limit: 1 },
  })

  const devices = fetchDevices.data?.devices ?? []
  const hasDevicesInstalled = devices.length > 0 && fetchDevices.isSuccess
  const breadcrumbs: IBreadcrumb[] = [
    {
      label: t(langKeys.report_plural),
      to: Routes.Reports.location(),
    },
    {
      label: t(langKeys.mold_risk_analytics),
      to: Routes.MoldInsights.location(),
    },
  ]

  return (
    <div>
      <BreadcrumbView
        breadcrumbs={breadcrumbs}
        title={
          <MText variant="heading1">{t(langKeys.mold_risk_analytics)}</MText>
        }
        description={""}
        actionBar={
          <InsightFilters
            dateRange={dateRange}
            setDateRange={setDateRange}
            selectedOption={selectedHomegroup}
            setSelectedOption={setSelectedHomegroup}
            hidden={!hasDevicesInstalled}
            disabled={!canAccessAdvancedMoldInsights}
            showUpgradeBlocker={!canAccessAdvancedMoldInsights}
            context="mold_analytics"
            requiredPlan={required_plan_types?.[0]}
            isDateBlocked={(date) => date < subDays(new Date(), 182)} // Fetch the last 6 months or more exactly 26 weeks
            intervalMs={1000 * 60 * 60 * 24 * 7} // 1 week in milliseconds
          />
        }
      >
        <MoldInsightsContent
          dateRange={dateRange}
          selectedHomegroup={selectedHomegroup}
          loading={fetchDevices.isLoading}
          hasDevicesInstalled={hasDevicesInstalled}
          timezone={timezone}
          setTimezone={setTimezone}
        />
      </BreadcrumbView>
    </div>
  )
}

function convertZonedDateRangeToUTC(
  dateRange: IGraphDateRange,
  timezone: TTimezone
) {
  return {
    startDate: zonedTimeToUtc(dateRange.startDate, timezone.tzCode),
    endDate: zonedTimeToUtc(dateRange.endDate, timezone.tzCode),
  }
}

function MoldInsightsContent({
  dateRange,
  selectedHomegroup,
  loading,
  hasDevicesInstalled,
  timezone,
  setTimezone,
}: {
  dateRange: IGraphDateRange
  selectedHomegroup: TSelectOption<string> | null
  loading: boolean
  hasDevicesInstalled: boolean
  timezone: TTimezone
  setTimezone: (timezone: TTimezone) => void
}) {
  const { t, langKeys } = useTranslate()

  if (loading) {
    return (
      <SkeletonBox>
        <MSkeleton width={"4ch"} height={300} />
        <MSkeleton width={"4ch"} height={300} />
        <MSkeleton width={"4ch"} height={300} />
        <MSkeleton width={"4ch"} height={300} />
        <MSkeleton width={"4ch"} height={300} />
        <MSkeleton width={"4ch"} height={300} />
        <MSkeleton width={"4ch"} height={300} />
        <MSkeleton width={"4ch"} height={300} />
      </SkeletonBox>
    )
  }

  if (!hasDevicesInstalled) {
    return <NoMoldInsights />
  }

  return (
    <Box>
      <MoldInsightsGraphCard
        dateRange={convertZonedDateRangeToUTC(dateRange, timezone)}
        homegroupId={selectedHomegroup?.value}
      />

      <MoldInsightsTable
        dateRange={convertZonedDateRangeToUTC(dateRange, timezone)}
        homegroupId={selectedHomegroup?.value}
      />

      <TimezoneContent>
        <MText variant="body">{t(langKeys.timezone)}: </MText>
        <TimezoneSelect timezone={timezone} onChange={setTimezone} />
      </TimezoneContent>
    </Box>
  )
}

const SkeletonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: ${spacing.M};
`

const Box = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`

const TimezoneContent = styled.div`
  display: flex;
  gap: ${spacing.S};
  align-items: center;
`
