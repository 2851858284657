import styled from "styled-components"

import MoldInsightUpgradeBlockerImage from "src/components/EmptyState/images/mold-insights-upgrade-blocker.svg?url"
import {
  TPlanUpgradeClickedEventProperties,
  usePostPlanUpgradeClicked,
} from "src/data/analytics/queries/planUpgradeAnalyticsQueries"
import { TPlan } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function UpgradeBlockerTable({
  eventProperties,
  plan,
}: {
  eventProperties: TPlanUpgradeClickedEventProperties
  plan?: TPlan
}) {
  const { navigate } = useRouter()
  const postPlanUpgradeClicked = usePostPlanUpgradeClicked()

  const { t, langKeys } = useTranslate()

  function handleClick() {
    postPlanUpgradeClicked.mutate({ ...eventProperties })
    navigate(Routes.ChangePlan.location({ plan }))
  }

  return (
    <ContentBox>
      <div>
        <MText variant="heading2" textAlign="center" marginBottom={spacing.XS}>
          {t(langKeys.mold_risk_level)}
        </MText>

        <MText variant="bodyS" textAlign="center">
          {t(langKeys.mold_analytics_upgrade_blocker_info)}
        </MText>
      </div>

      <div>
        <MButton onClick={handleClick}>
          {t(langKeys.subscription_dialog_positive_action)}
        </MButton>
      </div>
    </ContentBox>
  )
}

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.L};
  padding: ${spacing.XL};

  background-image: url(${MoldInsightUpgradeBlockerImage});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
`
