import styled from "styled-components"

import { HREF_MINUT_HELP_DECREASE_MOLD_RISK } from "src/constants/hrefs"
import { useTipLinkClicked } from "src/data/analytics/queries/moldAnalyticsQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import LightBulbIcon from "src/ui/icons/lightbulb-on.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function InfoSectionCard() {
  const { t, langKeys } = useTranslate()

  const postTipLinkClicked = useTipLinkClicked()

  return (
    <InfoCard boxShadow={false}>
      <div>
        <InfoHeader>
          <LightBulbIcon width={24} height={24} />
          <MText variant="subtitle">
            {t(langKeys.mold_analytics_tips_title)}
          </MText>
        </InfoHeader>

        <MText variant="bodyS" color="secondary">
          {t(langKeys.mold_analytics_tips_info)}{" "}
          <ExternalLink
            href={HREF_MINUT_HELP_DECREASE_MOLD_RISK}
            showExternalIcon={false}
            onClick={() =>
              postTipLinkClicked.mutate({ context: "mold_analytics" })
            }
          >
            {t(langKeys.mold_analytics_tips_link_text)}
          </ExternalLink>
        </MText>
      </div>
    </InfoCard>
  )
}

const InfoCard = styled(MCard)`
  display: grid;
  padding: ${spacing.L};
  background-color: ${mColors.accentPrimaryLight};
`

const InfoHeader = styled.div`
  display: grid;
  gap: ${spacing.XS};
  padding-bottom: ${spacing.M};
`
