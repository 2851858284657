import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { moldInsightKeys } from "src/data/moldInsights/moldInsighQueryCache"
import {
  TElevatedMoldRiskHomesDuration,
  TGroupedHomeMoldLevel,
} from "src/data/moldInsights/types/moldInsightTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

function moldInsightsApiPath(orgId: string) {
  return `${API_DEFAULT}/organizations/${orgId}/analytics/aggregated_group`
}

export function useFetchMoldInsightsByDate({
  orgId,
  filters,
  options,
}: {
  orgId: string
  filters: {
    startDate: Date | null
    endDate: Date | null
    homegroupId?: string
  }
  options?: UseQueryOptions<
    TGroupedHomeMoldLevel,
    AxiosError,
    TGroupedHomeMoldLevel,
    ReturnType<typeof moldInsightKeys.groupedByMoldRiskLevel>
  >
}) {
  async function fetchMoldInsightsByDate() {
    const apiPath = moldInsightsApiPath(orgId)

    const response = await minutApiHttpClient.get<TGroupedHomeMoldLevel>(
      `${apiPath}/home_count_by_mould_level`,
      {
        params: {
          from: filters.startDate?.toISOString(),
          to: filters.endDate?.toISOString(),
          homegroup_id: filters.homegroupId,
        },
      }
    )
    return response.data
  }

  return useQuery({
    queryKey: moldInsightKeys.groupedByMoldRiskLevel({
      orgId,
      ...filters,
    }),
    queryFn: fetchMoldInsightsByDate,
    ...options,
  })
}

export function useFetchElevatedMoldRiskHomes({
  orgId,
  filters,
  options,
}: {
  orgId: string
  filters: {
    startDate: Date | null
    endDate: Date | null
    homegroupId?: string
    limit?: number
    offset?: number
  }
  options?: UseQueryOptions<
    TElevatedMoldRiskHomesDuration,
    AxiosError,
    TElevatedMoldRiskHomesDuration,
    ReturnType<typeof moldInsightKeys.elevatedMoldRiskHomes>
  >
}) {
  async function fetchMoldRiskHomes() {
    const apiPath = moldInsightsApiPath(orgId)

    const response =
      await minutApiHttpClient.get<TElevatedMoldRiskHomesDuration>(
        `${apiPath}/elevated_mould_risk_duration`,
        {
          params: {
            from: filters.startDate?.toISOString(),
            to: filters.endDate?.toISOString(),
            homegroup_id: filters.homegroupId,
            limit: filters.limit,
            offset: filters.offset,
          },
        }
      )
    return response.data
  }

  return useQuery({
    queryKey: moldInsightKeys.elevatedMoldRiskHomes({ orgId, ...filters }),
    queryFn: fetchMoldRiskHomes,
    ...options,
  })
}
