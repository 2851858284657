import { AppStoreLogos } from "src/components/EmptyState/AppStoreLogos"
import { EmptyStateWithImage } from "src/components/EmptyState/EmptyStateWithImage"
import { useTranslate } from "src/i18n/useTranslate"
import { spacing } from "src/ui/spacing"

import MoldInsightsEmptyStateImage from "./images/mold-insights-graph-empty-state.svg"

export function NoMoldInsights() {
  const { t, langKeys } = useTranslate()

  return (
    <EmptyStateWithImage
      Image={MoldInsightsEmptyStateImage}
      title={t(langKeys.mold_analytics_empty_state_title)}
      description={t(langKeys.mold_analytics_empty_state_description)}
      content={<AppStoreLogos />}
      imageSpacing={spacing.M}
    />
  )
}
