import { useState } from "react"
import styled from "styled-components"

import { IconButton, mColors } from "@minuthq/meatball-ui-react"
import { differenceInWeeks } from "date-fns"

import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { MoldRiskGraphCard } from "src/components/Homes/DeviceDetails/Overview/MoldRiskGraphCard"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { PillsWrapper } from "src/components/Paradise/sharedStyles"
import { InfoSectionCard } from "src/components/Reports/MoldInsights/InfoSectionCard"
import { UpgradeBlockerTable } from "src/components/Reports/MoldInsights/UpgradeBlockerTable"
import { breakpoint } from "src/constants/breakpoints"
import { usePostViewGraphClicked } from "src/data/analytics/queries/moldAnalyticsQueries"
import { useFetchDevices } from "src/data/devices/queries/deviceQueries"
import { TDevice } from "src/data/devices/types/deviceTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { THome } from "src/data/homes/types/homeTypes"
import {
  useFetchElevatedMoldRiskHomes,
  useFetchMoldInsightsByDate,
} from "src/data/moldInsights/moldInsightsQueries"
import { TElevatedMoldRiskHomesDuration } from "src/data/moldInsights/types/moldInsightTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { Container } from "src/ui/Container/Container"
import { MDialog } from "src/ui/Dialog/MDialog"
import { GridTable } from "src/ui/GridTable/GridTable"
import CircleCheckMark from "src/ui/icons/checkmark-round.svg"
import GraphPlotIcon from "src/ui/icons/graph-plot.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

export function MoldInsightsTable({
  dateRange,
  homegroupId,
}: {
  dateRange: IGraphDateRange
  homegroupId?: string
}) {
  const limit = 5
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const { offset, setOffset } = useUrlPager()

  const postViewGraphClicked = usePostViewGraphClicked()

  const weeksInDateRange = differenceInWeeks(
    dateRange.endDate,
    dateRange.startDate
  )

  const [selectedHome, setSelectedHome] = useState<THome | null>(null)
  const [selectedDevice, setSelectedDevice] = useState<TDevice | null>(null)

  const { startDate, endDate } = dateRange

  const fetchElevatedMoldRiskHomes = useFetchElevatedMoldRiskHomes({
    orgId,
    filters: { startDate, endDate, homegroupId, limit, offset },
  })
  const elevatedMoldRiskHomes =
    fetchElevatedMoldRiskHomes.data?.response.data ?? []

  const fetchMoldInsightsByDate = useFetchMoldInsightsByDate({
    orgId,
    filters: { startDate, endDate, homegroupId },
  })

  const moldInsightsByDate = fetchMoldInsightsByDate.data?.data ?? []
  const hasMoldRiskReading = moldInsightsByDate.length > 0

  const deviceIds = elevatedMoldRiskHomes.map((r) => r.metadata.device_id)
  const fetchDevices = useFetchDevices({
    orgId,
    filter: { ids: deviceIds },
    options: { enabled: deviceIds.length > 0 },
  })
  const devices = fetchDevices.data?.devices ?? []

  const moldRiskHomeIds = elevatedMoldRiskHomes.map((r) => r.grouped_by)
  const fetchHomes = useFetchHomes({
    orgId,
    filters: { ids: moldRiskHomeIds },
    options: { enabled: moldRiskHomeIds.length > 0 },
  })
  const homes = fetchHomes.data?.homes ?? []

  const rows = elevatedMoldRiskHomes.map((h) => {
    const isDeletedDevice = !devices.find(
      (d) => d.device_id === h.metadata.device_id
    )

    return (
      <>
        <RowLeft>
          <InternalLink to={Routes.Home.location(h.grouped_by)}>
            {h.metadata.home_name}
          </InternalLink>

          {h.metadata.homegroup_name && (
            <MText color="tertiary" variant="bodyS">
              &bull; {h.metadata.homegroup_name}
            </MText>
          )}

          {isDeletedDevice && (
            <PillsWrapper>
              <MBadge color="warning" size="small">
                {t(langKeys.deleted_device)}
              </MBadge>
            </PillsWrapper>
          )}
        </RowLeft>
        <RowRight>
          {t(langKeys.duration_weeks, { weeks: h.aggregation })}
          <MTooltip title={t(langKeys.view_graph)} enabled={!isDeletedDevice}>
            <IconButton
              variant="subtle"
              Icon={GraphPlotIcon}
              size="small"
              onClick={() =>
                handleViewGraph({
                  moldRiskDeviceId: h.metadata.device_id,
                  moldRiskHomeId: h.grouped_by,
                })
              }
              disabled={isDeletedDevice}
            />
          </MTooltip>
        </RowRight>
      </>
    )
  })

  const mobileRows = elevatedMoldRiskHomes.map((h) => {
    const isDeletedDevice = !devices.find(
      (d) => d.device_id === h.metadata.device_id
    )

    return (
      <MobileRow key={h.grouped_by}>
        <MobileRowLeft>
          <InternalLink to={Routes.Home.location(h.grouped_by)}>
            {h.metadata.home_name}
          </InternalLink>
          {h.metadata.homegroup_name && (
            <MText color="tertiary" variant="bodyS">
              &bull; {h.metadata.homegroup_name}
            </MText>
          )}

          {isDeletedDevice && (
            <PillsWrapper>
              <MBadge color="warning" size="small">
                {t(langKeys.deleted_device)}
              </MBadge>
            </PillsWrapper>
          )}
        </MobileRowLeft>

        <MobileRowRight>
          {t(langKeys.duration_weeks, { weeks: h.aggregation })}
          <MTooltip title={t(langKeys.view_graph)} enabled={!isDeletedDevice}>
            <IconButton
              variant="subtle"
              Icon={GraphPlotIcon}
              size="small"
              onClick={() =>
                handleViewGraph({
                  moldRiskDeviceId: h.metadata.device_id,
                  moldRiskHomeId: h.grouped_by,
                })
              }
              disabled={isDeletedDevice}
            />
          </MTooltip>
        </MobileRowRight>
      </MobileRow>
    )
  })

  function handleViewGraph({
    moldRiskHomeId,
    moldRiskDeviceId,
  }: {
    moldRiskHomeId: string
    moldRiskDeviceId: string
  }) {
    setSelectedHome(homes.find((hl) => hl.home_id === moldRiskHomeId) ?? null)

    setSelectedDevice(
      devices.find((dl) => dl.device_id === moldRiskDeviceId) ?? null
    )

    postViewGraphClicked.mutate({ context: "mold_analytics" })
  }

  function handleClose() {
    setSelectedDevice(null)
    setSelectedHome(null)
  }

  return (
    <Container>
      <CardBox>
        <div>
          <Card>
            <Header>
              <MText variant="heading3">
                {t(langKeys.mold_analytics_elevated_table_title, {
                  weeks: weeksInDateRange,
                })}
              </MText>
              <MText variant="bodyS" color="secondary">
                {t(langKeys.mold_analytics_elevated_table_info)}
              </MText>
            </Header>

            <MoldInsightsTableContent
              rows={rows}
              mobileRows={mobileRows}
              elevatedMoldRiskHomes={elevatedMoldRiskHomes}
              hasMoldRiskReading={hasMoldRiskReading}
              loading={
                fetchElevatedMoldRiskHomes.isLoading ||
                fetchMoldInsightsByDate.isLoading
              }
            />
          </Card>

          <Pager
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            totalCount={fetchElevatedMoldRiskHomes.data?.paging.total_count}
          />
        </div>
        <InfoSectionCard />
      </CardBox>

      <MDialog
        open={!!selectedDevice && !!selectedHome}
        onClose={() => handleClose()}
        hideClose
        confirmLabel={t(langKeys.close)}
        onConfirm={() => handleClose()}
        width="full"
      >
        {selectedDevice && selectedHome && (
          <MoldRiskGraphCard
            hideGraphBorder={true}
            device={selectedDevice}
            home={selectedHome}
            context="mold_analytics"
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </MDialog>
    </Container>
  )
}

function MoldInsightsTableContent({
  rows,
  mobileRows,
  elevatedMoldRiskHomes = [],
  hasMoldRiskReading,
  loading,
}: {
  rows: JSX.Element[]
  mobileRows: JSX.Element[]
  elevatedMoldRiskHomes: TElevatedMoldRiskHomesDuration["response"]["data"]
  hasMoldRiskReading: boolean
  loading: boolean
}) {
  const { t, langKeys } = useTranslate()

  const { available: canAccessAdvancedMoldInsights, required_plan_types } =
    useFeatureAvailability({
      feature: "advanced_mould_risk_analytics",
    })

  const hasElevatedMoldRiskHomes = elevatedMoldRiskHomes.length > 0

  if (loading) {
    return (
      <div>
        <MSkeleton variant="text" width="100%" height={50} />
        <MSkeleton variant="text" width="100%" height={50} />
        <MSkeleton variant="text" width="100%" height={50} />
        <MSkeleton variant="text" width="100%" height={50} />
      </div>
    )
  }

  if (!canAccessAdvancedMoldInsights) {
    return (
      <UpgradeBlockerTable
        plan={required_plan_types?.[0]}
        eventProperties={{
          context: "mold_analytics",
          type: "section",
        }}
      />
    )
  }

  if (hasMoldRiskReading && !hasElevatedMoldRiskHomes) {
    return (
      <MBanner
        type="good"
        horizontal
        icon={<CircleCheckMark width={24} height={24} />}
        title={t(langKeys.mold_analytics_elevated_table_no_mold)}
      />
    )
  }

  if (hasElevatedMoldRiskHomes) {
    return (
      <GridTable
        rows={rows}
        mobileRows={mobileRows}
        templateColumns="1fr 1fr"
      />
    )
  }

  return (
    <MText variant="bodyS" color="tertiary">
      {t(langKeys.mold_analytics_elevated_table_collecting_data)}
    </MText>
  )
}

const CardBox = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 5fr) minmax(0, 1fr);
  gap: ${spacing.L};

  @container (${breakpoint.smallDown}) {
    grid-template-columns: 1fr;
  }
`

const Card = styled.div`
  display: grid;
  border: 1px solid ${mColors.divider};
  border-radius: ${spacing.L};
  padding: ${spacing.XL};
`

const Header = styled.div<{ $hideGraphBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.M};
`

const RowLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${spacing.XS};
`

const RowRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${spacing.M};
`

const MobileRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing.XS2};
  border-bottom: 1px solid ${mColors.divider};
  padding-bottom: ${spacing.S};
`

const MobileRowLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: ${spacing.XS};
`

const MobileRowRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${spacing.S};
`
