export const moldInsightKeys = {
  all() {
    return ["mold-insights"] as const
  },

  groupedByMoldRiskLevel(filters: {
    orgId: string
    startDate: Date | null
    endDate: Date | null
    homegroupId?: string
  }) {
    const { startDate, endDate, orgId, homegroupId } = filters

    return [...this.all(), orgId, startDate, endDate, homegroupId] as const
  },

  elevatedMoldRiskHomes(filters: {
    orgId: string
    startDate: Date | null
    endDate: Date | null
    homegroupId?: string
    limit?: number
    offset?: number
  }) {
    const { startDate, endDate, orgId, offset, limit, homegroupId } = filters

    return [
      ...this.all(),
      orgId,
      startDate,
      endDate,
      offset,
      limit,
      homegroupId,
    ] as const
  },
} as const
